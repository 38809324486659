import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./home.scss"

const Home = ({ data }) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ]
  return (
    <>
      <SEO title="Naslovna" />
      <Layout>
        <article id="naslovna">
          <header>
            <h1>
              Bahove <br></br> cvijetne <br></br> kapi
            </h1>
            <Img fluid={sources} />
          </header>
          <section>
            <h3>Dobro došli dragi gosti</h3>
            <p>
              Bahove cvijetne kapi su preparati napravljeni od biljaka, a
              namijenjeni našim mentalnim i emotivnim stanjima. Ovi preparati se
              dobijaju na vrlo jednostavan način – metodom osunčavanja ili
              metodom kuhanja i svi su napravljeni od prelijepih cvjetova, trava
              i drveća.
            </p>
            <p>
              Ono što je izuzetno važno je da nijedna biljka nije otrovna niti
              po bilo kom osnovu opasna za ljudsku upotrebu, bez obzira na koji
              način se koristi – spolja ili unošenjem u organizam. Iako ih ima{" "}
              <strong> „samo38“,</strong> pokrivaju svako moguće negativno
              emotivno stanje ljudske vrste.
            </p>
            <p>
              Ove cvijetne esencije imaju tu moć da naš rastrzan organizam koji
              je izbačen iz ravnoteže vraćaju u ravnotežu i tako omogućavaju
              ozdravljenje. Naš zdrav razum ponovo može da bude dostupan kao i
              sva naša ranija iskustva, jer upotrebom ovih kapi otklanjamo
              negativne emocije koje su bile preuzele primat. Mi možemo biti
              zdravi i sretni samo kada smo u harmoniji sa svojom prirodom i sve
              dok smo autentični. Kada se, pak, pojavi pukotina između našeg
              unutrašnjeg bića (naše autentičnosti – onoga kakvi stvarno jesmo i
              onoga kako se prikazujemo u okruženju ispunjavajući tuđa
              očekivanja), ta pukotina postaje izvor naše patnje.
            </p>
            <p id="box2">
              Svako udaljavanje od prave prirode dovodi do nezadovoljstva, a
              onda i do bolesti. Tako nastala fizička bolest samo oslikava našu
              emotivnu disharmoniju, nered koji je nastao usljed neslušanja
              unutrašnjeg glasa i njegovog neusvajanja, već, naprotiv,
              prilagođavanje životu koji je drugačiji od svega onog što nam naša
              priroda i instinkti sugerišu.
            </p>
            <p>
              Zato, da bi emotivno ostali zdravi, korišćenjem Bahovih kapi
              možemo da utičemo na našu unutrašnju neravnotežu, pretvarajući je
              u ravnotežu. Takođe, ove lijekove možemo koristiti i preventvno da
              bi spriječili oboljenja, npr. lijek prve pomoći – Rescue Remedy
              treba koristiti kad god postoji izloženost velikom stresu da bi se
              on neutralisao i tako izbjegle njegove štetne posljedice. Za osobe
              koje, pak, pretjerano rade i ne odustaju, čak i kad su na izmaku
              snage, kao preventiva je dobar preparat od hrasta da bi se
              spriječila mogućnost da se potpuno slome.
            </p>
            <p id="box3">
              Kapi treba koristiti i kada je neka bolest već ispoljena – u
              kombinaciji sa drugim metodama liječenja. U procesu rehabilitacije
              kapi će pomoći da oporavak od bolesti bude ne samo brži već i
              efikasniji. Uz njih možemo da prevaziđemo prepreke ka harmoniji sa
              samim sobom i sveukupnim okruženjem. Te prepreke su: posesivnost,
              sebičnost, strah, neodlučnost, ravnodušnost, nedostatak pažnje,
              dominacija, slabost volje, nedostatak samodiscipline, sumnja,
              pesimizam, depresija, neznanje, žalost, pretjerana brižnost, itd.
            </p>
            <p>
              Umjesto ovih prepreka možemo njegovati pozitivne osobine: ljubav,
              saosjećanje, duševni mir, postojanost, poniznost, snagu,
              razumijevanje, toleranciju, mudrost, opraštanje, hrabrost, itd.
            </p>
          </section>
        </article>
      </Layout>
    </>
  )
}

export default Home

export const query = graphql`
  query {
    mobileImage: file(name: { eq: "home-sm" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopImage: file(name: { eq: "naslovna-new" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid(quality: 100, traceSVG: { color: "#005b4b80" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
